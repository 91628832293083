import styled from 'styled-components';
import MEDIA from '../helpers/mediaTemplates';

const AboutWrapper = styled.div`
  display: flex;
  ${MEDIA.TABLET`
  flex-direction: column;
`};
  img {
    max-width: 100%;
  }
  .text-content {
    ${MEDIA.TABLET`
    margin: 1rem 1rem !important;
  `};
  }
  a {
    color: black;
  }
  strong {
    font-weight: bold;
  }
`;

export default AboutWrapper;
