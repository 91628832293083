import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import Head from 'components/head';
import Box from 'components/box';
import AboutWrapper from 'containers/aboutWrapper';

const About = ({ data }) => (
  <Layout darkMode noBg>
    <Box>
      <Head pageTitle="about me" />
    </Box>
    <div
      style={{
        margin: '100px auto',
        width: '60vw',
        // transform: 'translateX(50%)',
      }}
    >
      <AboutWrapper>
        <div style={{ minWidth: '30vw' }}>
          <img
            alt="Luis Mulet"
            src="https://res.cloudinary.com/luismulet/image/upload/q_auto:good/v1605031824/profilePhoto.jpg"
          />
        </div>
        <div
          className="text-content"
          style={{
            textAlign: 'left',
            margin: '0 3rem',
          }}
          dangerouslySetInnerHTML={{
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }}
        />
      </AboutWrapper>
    </div>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
